import Home from '../views/Home.vue';
import Index from '../views/index/Index.vue';
let routerSurface = [
  {
    path: '/',
    name: '',
    redirect: 'index',
    component: Home,
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/index.png',
      name: '首页',
      rules: 1,
    },
    children: [
      {
        path: 'index',
        component: Index,
        name: 'index',
        meta: {
          name: '首页',
          rules: 1,
          form: 1,
        },
      },
    ],
  },
  {
    path: '/goods',
    name: 'goods',
    component: Home,
    redirect: '/goods/goodsList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '商品',
      title: '商品管理',
      rules: 1,
    },
    children: [
      {
        path: '/goods/goodsList',
        component: () => import('@/views/goods/GoodsList'),
        name: 'goodsList',
        meta: {
          name: '商品列表',
          rules: 1,
        },
      },
      {
        path: '/goods/addGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'addGoods',
        meta: {
          name: '添加商品',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/goods/editGoods/:id/:page/:rows',
        component: () => import('@/views/goods/AddGoods'),
        name: 'editGoods',
        meta: {
          name: '编辑商品',
          relation: 'goodsList',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/goods/editGoodsType/:id',
        component: () => import('@/views/goods/AddType'),
        name: 'editGoodsType',
        meta: {
          name: '编辑分类',
          relation: 'goodsClassify',
          rules: 0,
        },
      },
      {
        path: '/goods/goodsClassify',
        component: () => import('@/views/goods/GoodsType'),
        name: 'goodsClassify',
        meta: {
          name: '商品分类',
          rules: 1,
        },
      },
      {
        path: '/goods/addGoodsClassify',
        component: () => import('@/views/goods/AddType'),
        name: 'addGoodsClassify',
        meta: {
          name: '添加分类',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/goods/goodsEvaluate',
        component: () => import('@/views/goods/GoodsEvaluate'),
        name: 'goodsEvaluate',
        meta: {
          name: '商品评价',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/order',
    name: 'order',
    component: Home,
    redirect: '/order/orderList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/order.png',
      name: '订单',
      title: '订单管理',
      rules: 1,
    },
    children: [
      {
        path: '/order/orderList',
        component: () => import('@/views/order/OrderList'),
        name: 'orderList',
        meta: {
          name: '订单列表',
          rules: 1,
        },
      },
      {
        path: '/order/salesRefund',
        component: () => import('@/views/order/SalesRefund'),
        name: 'salesRefund',
        meta: {
          name: '售后退款',
          rules: 1,
        },
      },
      {
        path: '/order/cashierOrder',
        name: 'cashierOrder',
        component: () => import('@/views/order/cashierOrder'),
        meta: {
          name: '收银台订单',
          rules: 1,
        },
      },
      {
        path: '/order/salesHandle/:id/:status/:page/:rows',
        component: () => import('@/views/order/SalesHandle'),
        name: 'salesHandle',
        meta: {
          name: '售后处理',
          relation: 'salesRefund',
          rules: 0,
        },
      },
      {
        path: '/order/orderInfo/:id/:page/:rows',
        component: () => import('@/views/order/OrderInfo'),
        name: 'orderInfo',
        meta: {
          name: '订单信息',
          relation: 'orderList',
          rules: 0,
        },
      },
    ],
  },
  {
    path: '/store',
    name: 'store',
    component: Home,
    redirect: '/store/storeSet',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/store.png',
      name: '门店',
      title: '门店管理',
      rules: 1,
    },
    children: [
      {
        path: '/store/storeSet',
        name: 'storeSet',
        component: () => import('@/views/store/Set'),
        meta: {
          name: '门店设置',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/store/storeUsersList',
        name: 'storeUsersList',
        component: () => import('@/views/store/ClerkList'),
        meta: {
          name: '店员账号管理',
          rules: 1,
        },
      },
      {
        path: '/store/addStoreUsers',
        name: 'addStoreUsers',
        component: () => import('@/views/store/AddClerk'),
        meta: {
          name: '添加店员账号',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/store/editClerk',
        name: 'editClerk',
        component: () => import('@/views/store/AddClerk'),
        meta: {
          name: '编辑店员账号',
          rules: 0,
          relation: 'storeUsersList',
          form: 1,
        },
      },
      {
        path: '/store/storeRolesList',
        name: 'storeRolesList',
        component: () => import('@/views/store/RoleList'),
        meta: {
          name: '店员角色管理',
          rules: 1,
        },
      },
      {
        path: '/store/freightList',
        name: 'freightList',
        component: () => import('@/views/store/Freightlist'),
        meta: {
          name: '运费设置',
          rules: 1,
        },
      },
      {
        path: '/store/freight',
        name: 'freight',
        component: () => import('@/views/store/Freight'),
        meta: {
          name: '添加运费',
          relation: 'freightList',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/store/freightEdit/:id/:page/:rows',
        name: 'freightEdit',
        component: () => import('@/views/store/Freight'),
        meta: {
          name: '编辑运费',
          relation: 'freightList',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/store/sourceCenter',
        name: 'sourceCenter',
        component: () => import('@/views/store/sourceCenter/index00.vue'),
        meta: {
          name: '素材中心',
          rules: 1,
        },
      },
      {
        path: '/store/printSheet',
        name: 'printSheet',
        component: () => import('@/views/store/printSheet'),
        meta: {
          name: '收银账号',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/extension',
    name: 'extension',
    component: Home,
    redirect: '/extension/couponList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/extension.png',
      name: '推广',
      title: '推广管理',
      rules: 1,
    },
    children: [
      {
        path: '/extension/couponList',
        name: 'couponList',
        component: () => import('@/views/extension/CouponList'),
        meta: {
          name: '优惠券列表',
          rules: 1,
        },
      },
      {
        path: '/extension/couponEdit',
        name: 'couponEdit',
        component: () => import('@/views/extension/CouponAdd'),
        meta: {
          name: '编辑优惠券',
          rules: 0,
          relation: 'couponList',
          form: 1,
        },
      },
      {
        path: '/extension/couponAdd',
        name: 'couponAdd',
        component: () => import('@/views/extension/CouponAdd'),
        meta: {
          name: '编辑优惠券',
          rules: 0,
          relation: 'couponList',
          form: 1,
        },
      },
      {
        path: '/extension/crossWebsite',
        name: 'extension-crossWebsite',
        redirect: '/extension/crossWebsite/renovation',
        component: () => import('@/views/extension/crossWebsite/index'),
        meta: {
          name: '跨境PC官网',
          rules: 1,
          isOpenThirdMenu: 0,
          isShowThirdMemu: 1,
        },
        children: [
          {
            path: '/extension/crossWebsite/renovation',
            name: 'extension-crossWebsite-renovation',
            component: () => import('@/views/extension/crossWebsite/renovation'),
            meta: {
              name: '官网装修',
              rules: 1,
            },
          },
          {
            path: '/extension/crossWebsite/recommendGoods',
            name: 'extension-crossWebsite-recommendGoods',
            component: () => import('@/views/extension/crossWebsite/recommendGoods'),
            meta: {
              name: '推荐商品',
              rules: 1,
              form: 1,
            },
          },
          {
            path: '/extension/crossWebsite/shortVideoList',
            name: 'extension-crossWebsite-shortVideoList',
            component: () => import('@/views/extension/crossWebsite/shortVideoList'),
            meta: {
              name: '短视频列表',
              rules: 1,
            },
          },
          {
            path: '/extension/crossWebsite/addShortVideo',
            name: 'extension-crossWebsite-addShortVideo',
            component: () => import('@/views/extension/crossWebsite/addShortVideo'),
            meta: {
              name: '添加短视频',
              rules: 1,
              form: 1,
            },
          },
          {
            path: '/extension/crossWebsite/editShortVideo',
            name: 'extension-crossWebsite-editShortVideo',
            component: () => import('@/views/extension/crossWebsite/addShortVideo'),
            meta: {
              name: '编辑短视频',
              rules: 0,
              relation: 'extension-crossWebsite-shortVideoList',
              form: 1,
            },
          },
        ],
      },
      {
        path: '/extension/gatherTweeters',
        name: 'gatherTweeters',
        redirect: '/extension/gatherTweeters/enterpriseGoods',
        component: () => import('@/views/extension/crossWebsite/index'),
        meta: {
          name: '企业管理',
          rules: 1,
          isOpenThirdMenu: 0,
          isShowThirdMemu: 1,
        },
        children: [
          {
            path: '/extension/gatherTweeters/enterpriseGoods',
            name: 'extension-gatherTweeters-enterpriseGoods',
            component: () => import('@/views/extension/gatherTweeters/enterpriseGoods'),
            meta: {
              name: '企业商品',
              rules: 1,
            },
          },
          {
            path: '/extension/gatherTweeters/enterpriseGoodsAdd',
            name: 'extension-gatherTweeters-enterpriseGoodsAdd',
            component: () => import('@/views/extension/gatherTweeters/enterpriseGoodsEdit'),
            meta: {
              name: '添加商品',
              rules: 0,
              relation: 'extension-gatherTweeters-enterpriseGoods',
              form: 1,
            },
          },
          {
            path: '/extension/gatherTweeters/enterpriseGoodsEdit',
            name: 'extension-gatherTweeters-enterpriseGoodsEdit',
            component: () => import('@/views/extension/gatherTweeters/enterpriseGoodsEdit'),
            meta: {
              name: '编辑商品',
              rules: 0,
              relation: 'extension-gatherTweeters-enterpriseGoods',
              form: 1,
            },
          },
          {
            path: '/extension/gatherTweeters/enterpriseOrderList',
            name: 'extension-gatherTweeters-enterpriseOrderList',
            component: () => import('@/views/extension/gatherTweeters/enterpriseOrderList'),
            meta: {
              name: '企业订单',
              rules: 1,
            },
          },
          {
            path: '/extension/gatherTweeters/enterpriseOrderInfo',
            name: 'extension-gatherTweeters-enterpriseOrderInfo',
            component: () => import('@/views/extension/gatherTweeters/enterpriseOrderInfo'),
            meta: {
              name: '订单详情',
              rules: 0,
              relation: 'extension-gatherTweeters-enterpriseOrderList',
            },
          },
          {
            path: '/extension/gatherTweeters/enterpriseRefund',
            name: 'extension-gatherTweeters-enterpriseRefund',
            component: () => import('@/views/extension/gatherTweeters/enterpriseRefund'),
            meta: {
              name: '售后退款',
              rules: 1,
            },
          },
          {
            path: '/extension/gatherTweeters/enterpriseOrderInfoRefund',
            name: 'extension-gatherTweeters-enterpriseOrderInfoRefund',
            component: () => import('@/views/extension/gatherTweeters/enterpriseOrderInfoRefund'),
            meta: {
              name: '订单详情',
              rules: 0,
              relation: 'extension-gatherTweeters-enterpriseRefund',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/finance',
    name: 'finance',
    component: Home,
    redirect: '/finance/withdrawnList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/money.png',
      name: '财务',
      title: '财务管理',
      rules: 1,
    },
    children: [
      {
        path: '/finance/withdrawnList',
        name: 'withdrawnList',
        component: () => import('@/views/finance/FinancialReconciliation'),
        meta: {
          name: '财务对账',
          rules: 1,
        },
      },
      {
        path: '/finance/withdrawLog',
        name: 'withdrawLog',
        component: () => import('@/views/finance/WithdrawalRecord'),
        meta: {
          name: '提现记录',
          rules: 1,
        },
      },
      {
        path: '/finance/withdrawalrecordDetail',
        name: 'withdrawalrecordDetail',
        component: () => import('@/views/finance/WithdrawalrecordDetail'),
        meta: {
          name: '提现详情',
          relation: 'withdrawLog',
          rules: 0,
        },
      },
      {
        path: '/finance/account',
        name: 'account',
        component: () => import('@/views/finance/WithdrawalAccount'),
        meta: {
          name: '提现账号',
          rules: 1,
          form: 1,
        },
      },
    ],
  },
  // 硬件
  {
    path: '/hardware',
    name: 'hardware',
    component: Home,
    redirect: '/hardware/printTicket',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/hardware.png',
      name: '硬件',
      title: '硬件管理',
      rules: 1,
    },
    children: [
      {
        path: '/hardware/printTicket',
        name: 'printTicket',
        component: () => import('@/views/hardware/printTicket'),
        meta: {
          name: '打印机设置',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/hardware/scanCodeGun',
        name: 'scanCodeGun',
        component: () => import('@/views/hardware/scanCodeGun'),
        meta: {
          name: '扫描枪',
          rules: 1,
        },
      },
      {
        path: '/hardware/Pad',
        name: 'pad',
        component: () => import('@/views/hardware/Pad'),
        meta: {
          name: 'PAD',
          rules: 1,
        },
      },
      {
        path: '/hardware/labelPrinter',
        name: 'labelPrinter',
        component: () => import('@/views/hardware/labelPrinter'),
        meta: {
          name: 'labelPrinter',
          rules: 1,
          form: 1,
        },
      },
    ],
  },
  //  餐饮
  {
    path: '/catering',
    name: 'catering',
    component: Home,
    redirect: '/catering/goodsList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '餐饮',
      title: '餐饮管理',
      rules: 1,
    },
    children: [
      {
        path: '/catering/goodsList',
        name: 'catering-goodsList',
        component: () => import('@/views/catering/goodsList'),
        meta: {
          name: '餐饮菜品',
          rules: 1,
        },
      },
      {
        path: '/catering/addGoods',
        name: 'catering-addGoods',
        component: () => import('@/views/catering/goodsform'),
        meta: {
          name: '添加菜品',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/catering/editGoods',
        name: 'catering-editGoods',
        component: () => import('@/views/catering/goodsform'),
        meta: {
          name: '编辑菜品',
          relation: 'catering-goodsList',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/catering/goodsType',
        name: 'catering-goodsType',
        component: () => import('@/views/catering/goodsType'),
        meta: {
          name: '菜品分类',
          rules: 1,
        },
      },
      {
        path: '/catering/practice',
        name: 'catering-practice',
        component: () => import('@/views/catering/practice'),
        meta: {
          name: '做法规格',
          rules: 1,
        },
      },
      {
        path: '/catering/order',
        name: 'catering-order',
        component: () => import('@/views/catering/order'),
        meta: {
          name: '菜品订单',
          rules: 1,
        },
      },
      {
        path: '/catering/orderDetail',
        name: 'catering-orderDetail',
        component: () => import('@/views/catering/orderDetail'),
        meta: {
          name: '菜品详情',
          rules: 0,
          relation: 'catering-order',
        },
      },
      {
        path: '/catering/set',
        name: 'catering-set',
        component: () => import('@/views/catering/set'),
        meta: {
          name: '基础设置',
          rules: 1,
        },
      },
    ],
  },
];
export default routerSurface;
