<template>
  <div class="bigNo">
    <div>
      <img src="../assets/image/no.png" alt="" />
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    text: {
      default: '暂无内容'
    }
  }
}
</script>

<style lang="less" scoped>
.bigNo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
    font-size: 14px;
    color: #909399;
    margin: 0 auto;
    p {
      margin: 10px 0;
    }
  }
}
</style>
