const api = {
  user: {
    login: '/Login/login',
    logout: '/Login/logout',
    userInfo: '/Login/self',
    addFiles: '/Upload/addFiles',
    imgList: '/image/list',
    shopInfo: '/Store/info', //店铺信息
    getMenu: '/Auths/getMenu', //获取菜单
    getAuths: '/Auths/getAuths', //获取全部菜单
    
  },
  goods: {
    goodsClass: '/StoreGoodsClassify/list',
    addGoods: '/Goods/add',
    addType: '/StoreGoodsClassify/add',
    list: '/Goods/list',
    editInfo: '/Goods/editInfo', //编辑商品信息
    delType: '/StoreGoodsClassify/del',
    delGoods: '/Goods/del',
    editGoods: '/Goods/edit',
    editType: '/StoreGoodsClassify/edit',
    goodsInfo: '/Goods/info',
    shiftGoods: '/Goods/shiftGoods',
    commentList: '/GoodsComment/list', //评价列表
    reply: '/GoodsComment/reply', //回复
    setStatus: '/GoodsComment/setStatus', //评价显示隐藏
    delComment: '/GoodsComment/del', //删除评价
    setPutaway: '/Goods/setPutaway', //设置上架\下架
    goodsStatus: '/Goods/storeGoodsStatus', //商品状态
    isSelect: '/Goods/isSelect', //是否有默认运费模块
    export: '/Goods/export', //导出商品
    import: '/Goods/import', //导入商品
    copyGoods: '/Goods/copyGoods', //复制商品
    goodsAudit: '/Goods/goodsAudit', //发布审核
    goodsAuditLog: '/Goods/goodsAuditLog', //审核记录
    wxCode: '/Goods/wxCode',
    setFreight: '/Goods/setFreight',
    setClassify: '/Goods/setClassify',
    exportGoodsClass: '/StoreGoodsClassify/export', //导出商品分类
  },
  order: {
    list: '/order/list', //获取订单列表
    info: '/order/info', //获取订单详情
    setSend: '/order/setSend', //订单发货接口
    num: '/order/stateNum', //订单数量
    express: '/express/list', //物流信息
    logisticsQuery: '/Order/logisticsQuery', //物流信息查询
    setSystemMessage: '/Order/setSystemMessage', //添加系统备注信息
    changePrice: '/Order/changePrice', //修改订单价格
    cancel: '/Order/cancel', //取消未付款订单
    cancelList: '/Orderrefundreason/list', //退款原因
    printOrder: '/Order/printOrder', //打印订单
    handleRefund: '/Orderrefund/handle', //处理退款
    salesList: '/Orderrefund/list', //售后列表
    refundInfo: '/Orderrefund/info', //退款详情
    receive: '/Orderrefund/receive', //商家收货
    refund: '/Orderrefund/refund', //原路退回到钱包
    confirmReceipt: '/order/confirmReceipt', //订单确认收货
    refundNum: '/Orderrefund/stateNum', //退款订单数量
  },
  freight: {
    list: '/Freight/list', //运费列表
  },
  store: {
    edit: '/Store/edit',
    storeUsersList: '/StoreUsers/list', //店员列表
    addStoreUsers: '/StoreUsers/add', //添加店员
    editStoreUsers: '/StoreUsers/edit', //编辑店员
    delStoreUsers: '/StoreUsers/del', //删除店员
    rolesList: '/StoreRoles/list', //门店角色列表
    addRoles: '/StoreRoles/add', //添加门店角色
    editRoles: '/StoreRoles/edit', //编辑门店角色
    delRoles: '/StoreRoles/del', //删除门店角色

    sendSetinfo: '/StoreSendSet/info',
    sendSetEdit: '/StoreSendSet/edit',
  },
  set: {
    editVideo: '/Upload/editVideo', // 添加视频信息
    freightAdd: '/Freight/add', //添加运费
    freightList: '/Freight/list', //运费列表
    freightDel: '/Freight/del', //删除运费
    freightEdit: '/Freight/edit', //编辑运费
    setDefaultFreight: '/Freight/setDefault', //设置默认运费
    Settinginfo: '/ShopSetting/info', // 获取设置信息
    Settingedit: '/ShopSetting/edit', // 修改设置
    Settingadd: '/ShopSetting/add', // 添加设置
    area: '/area/list', //省市区
    ShopSms: '/ShopSms/getData',
  },
  //推广
  push: {
    couponList: '/Coupon/list', //优惠券列表
    editStatus: '/Coupon/editStatus',
    addCoupon: '/Coupon/add',
    couponEdit: '/Coupon/edit',
    couponEel: '/Coupon/del',
  },
  // 首页
  homeIndex: {
    rsealTime: '/Index/top', // 实时概况
    msgNotification: '/Index/message', // 消息通知
    shortcut: '/Index/shortcut', // 快捷入口
    echarsData: '/Index/data', // 数据展示
    goodsRanking: '/Index/goodsTop', //商品排行
    readMsg: '/Index/readMessage', // 阅读消息通知
    topCompany: '/Index/topCompany',
  },
  // 素材中心
  sourceCenter: {
    ImagesGroupList: '/ImagesGroup/list', //所有图片分组
    ImagesGroupSort: '/ImagesGroup/sort', //图片分组排序
    ImagesGroupAdd: '/ImagesGroup/add', // 添加图片分组
    ImagesGroupEdit: '/ImagesGroup/edit', // 修改图片分组
    ImagesGroupDel: '/ImagesGroup/del', // 删除图片分组
    ImageMove: '/Image/move', // 移动图片分组
    ImageDel: '/Image/dels', // 删除图片
    UpdateName: '/Image/rename', //修改图片名字
    JwtList: '/JwtImages/JwtList', // 站点外获取图片分类
    rename: '/ImagesGroup/rename', // 修改文件夹名字
    moveGroup: '/ImagesGroup/move', // 移动文件夹
  },
  //财务
  finance: {
    accountInfo: '/Store/accountInfo', //账户信息
    editAccount: '/Store/editAccount', //编辑账户信息
    withdrawnList: '/Withdraw/withdrawnList', //提现订单列表
    withdraw: '/Withdraw/withdraw', //提现
    status: '/Withdraw/getStatus', //提现记录状态
    withdrawLog: '/Withdraw/withdrawLog', //提现记录
    getWxOpenid: '/Store/getWxOpenid', //微信用户
    withdrawLogExport: '/Withdraw/withdrawLogExport', //导出提现记录
  },
  //收银台
  cashier: {
    CashierUsersList: '/CashierUsers/list', //账号列表
    CashierUsersAdd: '/CashierUsers/add', //添加账号
    CashierUsersEdit: '/CashierUsers/edit', //编辑账号
    CashierUsersDel: '/CashierUsers/del', //删除账号
    CashierOrder: '/Order/cashierOrder', //收银订单列表
    CashierOrderStatus: '/Order/cashierOrderStatus', //收银订单状态列表
    PrinterList: '/Printer/list', //打印机设备列表
    PrinterAdd: '/Printer/add', //添加打印机设备
    CashierPrinterConfig: '/PrinterConfig/info', //获取打印机配置信息
    CashierPrinterEdit: '/PrinterConfig/edit', //获取打印机配置信息
  },
  en: {
    storeInfo: '/En/Store/info',
    storeClassify: '/En/Store/classifyList',
    editStoreInfo: '/En/Store/edit',
    recommendGoodsInfo: '/En/StoreRecommendGoods/info',
    editRecommendGoods: '/En/StoreRecommendGoods/edit',
    list: '/En/StoreRecommendGoods/list',
    addVideoInfo: '/En/ShortVideo/add',
    editVideoInfo: '/En/ShortVideo/edit',
    delVideoInfo: '/En/ShortVideo/del',
    getVideoInfo: '/En/ShortVideo/info',
    getVideoClassifyList: '/En/ShortVideo/classifyList',
    videoList: '/En/ShortVideo/list',
    quickEditVideoInfo: '/En/ShortVideo/editInfo',
  },
  serviceProvider: {
    companyGoods: '/CompanyGoods/list', // 企业商品列表
    companyGoodsSkusCps: '/CompanyGoodsSkusCps/list', // 企业cps推广商品
    companyList: '/CompanyGoods/companyList',
    upStatus: '/CompanyGoods/upStatus', // 更改企业商品状态
    delCGoods: '/CompanyGoods/del', // 删除企业商品
    companyGoodsInfo: '/CompanyGoods/info',
    CompanyGoodsSkusCpsSubmit: '/CompanyGoodsSkusCps/submit', // 提交cps商品
    upIsHot: '/CompanyGoodsSkusCps/upIsHot', // 修改爆款商品状态
    delCpsGoods: '/CompanyGoodsSkusCps/del', // 删除cps商品信息
    catsList: '/CompanyGoods/catsList', // 企业自营商品 视频号商品分类列表
    delSku: '/CompanyGoods/delSku', // 删除商品规格
    editGoods: '/CompanyGoods/edit', // 编辑商品
    addGoods: '/CompanyGoods/add', // 企业添加商品
    addSku: '/CompanyGoods/addSku', // 添加商品规格
    editSku: '/CompanyGoods/editSku', // 编辑商品规格

    companyOrder: '/CompanyOrder/list', // 企业订单
    companyOrderStateNum: '/CompanyOrder/stateNum', // 企业订单数量
    companyOrderInfo: '/CompanyOrder/info', //企业订单详情
    companyOrderSend: '/CompanyOrder/setSend', //企业订单发货
    logisticsQuery: '/CompanyOrder/logisticsQuery', //物流信息
    companyOrderCancel: '/CompanyOrder/cancel',

    companyOrderRefundList: '/CompanyOrderRefund/list', // 获取退款列表
    companyOrderInfoRefund: '/CompanyOrderRefund/info', // 获取退款列表详情
    companyOrderStateNum: '/CompanyOrderRefund/stateNum', // 获取退款订单数量
    companyOrderHandle: '/CompanyOrderRefund/handle', // 获取退款订单数量
    companyOrderRefund: '/CompanyOrderRefund/refund', // 获取退款订单数量
    companyOrderReceive: '/CompanyOrderRefund/receive', // 获取退款订单数量

    setDefault: '/Shipper/list', // 获取退款订单数量

    
  },
  //  新餐饮版
  catering: {
    orderDetail:'/ShopCatering/Order/orderDetail',
    GoodsList: '/ShopCatering/Goods/list',
    GoodsEditInfo: '/ShopCatering/Goods/editInfo',
    GoodsEdit: '/ShopCatering/Goods/edit',
    GoodsAdd: '/ShopCatering/Goods/add',
    GoodsInfo: '/ShopCatering/Goods/info',
    GoodsSkuList: '/ShopCatering/Goods/skuList',
    GoodsSkuDel: '/ShopCatering/Goods/skuDel',
    GoodsSkuEdit: '/ShopCatering/Goods/skuEdit',
    GoodsSkuAdd: '/ShopCatering/Goods/skuAdd',
    GoodsDel: '/ShopCatering/Goods/del',
    GoodsClassifyList: '/ShopCatering/GoodsClassify/list',
    GoodsClassifyAdd: '/ShopCatering/GoodsClassify/add',
    GoodsClassifyEdit: '/ShopCatering/GoodsClassify/edit',
    GoodsClassifyDel: '/ShopCatering/GoodsClassify/del',
    GoodsClassifyLists: '/ShopCatering/GoodsClassify/lists',
    GoodsClassifyEditSort: '/ShopCatering/GoodsClassify/editSort',
    GoodsClassifyEditIsRequired: '/ShopCatering/GoodsClassify/editIsRequired',
    GoodsPracticeList: '/ShopCatering/GoodsPractice/list',
    GoodsPracticeAdd: '/ShopCatering/GoodsPractice/add',
    GoodsPracticeEdit: '/ShopCatering/GoodsPractice/edit',
    GoodsPracticeDel: '/ShopCatering/GoodsPractice/del',
    GoodsPracticeClassifyList: '/ShopCatering/GoodsPracticeClassify/list',
    GoodsPracticeClassifyLists: '/ShopCatering/GoodsPracticeClassify/lists',
    GoodsPracticeClassifyAdd: '/ShopCatering/GoodsPracticeClassify/add',
    GoodsPracticeClassifyDel: '/ShopCatering/GoodsPracticeClassify/del',
    GoodsPracticeClassifyEdit: '/ShopCatering/GoodsPracticeClassify/edit',
    OrderSetInfo: '/ShopCatering/OrderSet/info',
    OrderSetEdit: '/ShopCatering/OrderSet/edit',
    OrderList: '/ShopCatering/Order/orderList',
    OrderFinish: '/ShopCatering/Order/finish',
    OrderCancle: '/ShopCatering/Order/cancle',

    LabelPrinterInfo: '/ShopCatering/LabelPrinter/info',
    LabelPrinterEdit: '/ShopCatering/LabelPrinter/edit',
  },
};

export default api;
