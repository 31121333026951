const getters = {
  menuLeft: (state) => {
    let menuLeft = state.user.menuLeft.filter((i) => i.meta.rules);
    let newArray = [];
    menuLeft.forEach((now, index) => {
      newArray[index] = now;
      newArray[index].children = now.children.filter((item) => item.meta.rules);
      newArray[index].children.forEach(now => {
        if (now.children) {
          now.children = now.children.filter(item => item.meta.rules);
        }
      });
    });
    return newArray;
  },
  relationMenu: (state) => {
    return state.user.relationMenu;
  },
  userInfo: (state) => state.user.userInfo,
  shopInfo: (state) => state.user.shopInfo,
  goodsTypeList: (state) => state.goods.goodsTypeList,
};
export default getters;
