<template>
    <div class="indexbox" ref="bo1" v-if="rsealTime">
        <div class="indexBoxLeft">
            <div class="realtime">
                <div class="realtimetop">
                    <p class="title">实时概况</p>
                    <p class="timeTxt">更新时间：{{ nowDate }}</p>
                    <p class="iconbox">
                        <i v-show="isShow" @click="isShow = !isShow" class="iconfont icon-yanjing"></i>
                        <i v-show="!isShow" @click="isShow = !isShow" class="iconfont icon-biyan"></i>
                    </p>
                </div>
                <div class="realtimebot">
                    <div class="listItem">
                        <div class="topItem" v-for="(item, index) in rsealTime.slice(0, 4)" :key="index">
                            <div class="today">
                                <p>{{ isShow ? item.today : '*****' }}</p>
                                <p>{{ item.name }}</p>
                            </div>
                            <div class="yesterday" v-if="item.is_yesterday">
                                <p>昨日：{{ isShow ? item.yesterday : '*****' }}</p>
                                <p>
                                    <i v-if="isShow" :style="{ color: item.rise >= 0 ? '#FD5E94' : '#0BCED9' }"
                                        :class="item.rise >= 0 ? 'el-icon-top' : 'el-icon-bottom'"></i>
                                    <span :style="{ color: item.rise >= 0 ? '#FD5E94' : '#0BCED9' }">
                                        <span v-if="isShow">{{ item.rise == 0 ? '0.00' : item.rise }}%</span>
                                        <span v-else>*****</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="listItem">
                        <div class="topItem" v-for="(item, index) in rsealTime.slice(4, 6)" :key="index">
                            <div class="today">
                                <p>{{ isShow ? item.today : '*****' }}</p>
                                <p>{{ item.name }}</p>
                            </div>
                        </div>
                        <template v-if="userInfo.company_id">
                            <div class="topItem" v-for="(item, index) in topCompany" :key="index">
                                <div class="today">
                                    <p>{{ isShow ? item.today : '*****' }}</p>
                                    <p>{{ item.name }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="quickaccess">
                <div class="qickList">
                    <div class="quickItem" @click="toPath(item)" v-for="(item, index) in shortcut" :key="index">
                        <img :src="item.icon" alt="" />
                        <p>{{ item.name }}</p>
                        <span v-if="item.is_need == 1 && item.flag == true">
                            （{{
                                item.name == '待发货订单'
                                ? indexData.info.no_send_count
                                : item.name == '维权订单'
                                    ? indexData.info.refund_count
                                    : item.name == '商品评论'
                                        ? indexData.info.yesterday_positive_count
                                        : item.name == '提现审核'
                                            ? indexData.withdrawal_audit_num
                                            : ''
                            }}）
                        </span>
                    </div>
                </div>
            </div>
            <div class="paymentamount">
                <div class="titleBox">
                    <p>运营趋势</p>
                    <!-- <el-select v-model="showDay" style="width: 100px" size="small" placeholder="请选择" @change="val => (showDay = val)">
            <el-option label="今日" :value="0"></el-option>
            <el-option label="昨日" :value="1"></el-option>
            <el-option label="近七日" :value="2"></el-option>
            <el-option label="本月" :value="3"></el-option>
          </el-select> -->
                </div>
                <div class="totalData">
                    <el-radio-group v-model="totalDataType" @change="changeTotalData">
                        <el-radio :label="1">支付金额</el-radio>
                        <el-radio :label="0">支付订单数</el-radio>
                    </el-radio-group>
                    <!-- <div class="xds">
            <div class="xdsNum">{{ showWhich.all_count }} / {{ showWhich.all_create_count }}</div>
            <div class="xdsTxt">支付数(个) / 下单数(个)</div>
          </div>
          <div class="xds">
            <div class="xdsNum" style="color: #3370ff">{{ showWhich.all_cost }} / {{ showWhich.all_create_cost }}</div>
            <div class="xdsTxt">支付金额(元) / 下单金额(元)</div>
          </div>
          <div class="xds">
            <div class="xdsNum" style="color: #de63fc">{{ showWhich.cost_average }}</div>
            <div class="xdsTxt">人均消费(元)</div>
          </div> -->
                </div>
                <div id="eBox" ref="eBox"></div>
            </div>
        </div>
        <div class="indexBoxRight">
            <div class="righttop">
                <div class="righttopT">
                    <div class="titleBox">
                        <div class="title">消息通知</div>
                        <div class="ignoreall" @click="ignoreall">全部忽略</div>
                    </div>
                    <div class="listBox" style="display: flex; justify-content: center; align-items: center"
                        v-if="msgNotification.length == 0">
                        <img :src="imgH + 'msgnull.png'" alt="" />
                    </div>
                    <div v-else class="listBox">
                        <div class="itemBox" v-for="(item, index) in msgNotification" :key="index">
                            <div class="content">【通知】{{ item.content }}</div>
                            <div class="time">{{ item.create_time | setTiem() }}</div>
                            <div class="todeal" @click="toDealMsg(item, index)">去处理</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="righttopB">
                <div class="customer">
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/%E5%AE%A2%E6%9C%8D.png" alt="" />
                    <p>在线客服</p>
                </div>
                <div class="money">
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/%E6%94%B6%E9%93%B6%E5%8F%B0.png"
                        alt="" />
                    <p>高效收银台</p>
                </div>
            </div>
            <div class="rightbot">
                <div class="titleBox">商品排行</div>
                <div class="goodsRanking" style="display: flex; justify-content: center; align-items: center"
                    v-if="goodsRanking.length == 0">
                    <img :src="imgH + 'goodsnull.png'" alt="" />
                </div>
                <div class="goodsRanking" v-else>
                    <div class="goodsItem" v-for="(item, index) in goodsRanking" :key="index">
                        <div class="rankingNum">{{ index + 1 }}</div>
                        <div class="goodsInfo">
                            <div class="imgBox">
                                <img :src="item.goods_picture" alt="" />
                            </div>
                            <div class="infoRight">
                                <div class="goodsName" style="color: #17171a; font-weight: bold">
                                    {{ item.goods_name }}
                                </div>
                                <div style="color: #17171a">¥{{ item.price }}</div>
                            </div>
                        </div>
                        <div class="salesNum">
                            卖出
                            <span style="color: #fd5e94">{{ item.sales }}</span>
                            件
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import { imgUrlHead } from '@/util/config';
import * as echarts from 'echarts';
import _ from 'lodash';
export default {
    data () {
        return {
            isShow: true,
            // shan
            indexData: {},
            // 实时概况
            rsealTime: [],
            // 消息通知
            msgNotification: [],
            // 快捷入口
            shortcut: [],
            // 展示数据
            echarsData: {},
            // 商品排行
            goodsRanking: [],
            nowDate: null,
            flag: false,
            showDay: 0,
            showWhich: {},
            imgH: imgUrlHead,
            totalDataType: 1,
            option: {},
            topCompany: [],
            myChart: null,
        };
    },
    computed: {
        // 登录的用户信息
        userInfo () {
            return this.$store.getters.userInfo;
        },
    },
    created () {
        this.nowDate = getDateformat(new Date().getTime() / 1000);
        this.getIndexData();
        this.getMsgList();
        this.getShortcut();
        this.getEcharsData();
        this.getTopCompany()
        let that = this;
        let timer = setInterval(() => {
            if (that.flag) {
                let box = document.getElementById('eBox');
                var myChart = echarts.init(box);
                this.myChart = myChart;
                let dataConfig = that.echarsData.getEveryDayData;
                let option = {
                    color: ['#1467FF'],
                    title: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            },
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: dataConfig.date,
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                        },
                    ],
                    series: [
                        {
                            name: '支付金额',
                            type: 'line',
                            smooth: true,
                            lineStyle: {
                                width: 1.5,
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.1,
                                color: '#1467FF',
                                origin: 'start',
                            },

                            data: dataConfig.order_price,
                            // data: [124, 245, 676, 468, 237, 236, 123, 543, 277, 646, 23, 636, 123, 543, 277, 346, 273, 436]
                        },
                        // {
                        //   barGap: '-100%',
                        //   name: '订单金额',
                        //   type: 'line',
                        //   smooth: true,
                        //   lineStyle: {
                        //     width: 0,
                        //   },
                        //   showSymbol: false,
                        //   areaStyle: {
                        //     opacity: 0.8,
                        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //       {
                        //         offset: 0,
                        //         color: '#97C0FE',
                        //       },
                        //       {
                        //         offset: 1,
                        //         color: '#97C0FE',
                        //       },
                        //     ]),
                        //   },
                        //   emphasis: {
                        //     focus: 'series',
                        //   },
                        //   data: dataConfig.order_price,
                        //   // data: [123, 543, 222, 366, 234, 236, 123, 543, 233, 366, 233, 646, 123, 543, 392, 346, 263, 436]
                        // },
                    ],
                };
                this.option = option;
                option && myChart.setOption(this.option);
                that.flag = false;
                window.clearInterval(timer);
            }
        }, 50);
    },
    methods: {
        changeTotalData (type) {
            let dataConfig = this.echarsData.getEveryDayData;
            if (type) {
                this.option.series[0].data = dataConfig.order_price;
                this.option.series[0].name = '支付金额';
            } else {
                this.option.series[0].data = dataConfig.order_num;
                this.option.series[0].name = '支付订单数';
            }
            this.myChart.clear(this.option);
            this.myChart.setOption(this.option);
        },
        // 点击去处理消息通知
        toDealMsg (item, index) {
            if (item.type == 6) {
                this.$message({
                    message: '暂未开放客服模块',
                    type: 'warning',
                });
                return;
            }
            let path = '';
            let that = this;
            switch (item.type) {
                case 1:
                    path = '/order/orderList?tab_type=state_pay';
                    break;
                case 2:
                    path = '/order/salesRefund?type=1';
                    break;
                case 3:
                    path = `/store/storeManagement?id=${item.store_id}`;
                    break;
                case 4:
                    path = `/store/reflectAudit?id=${item.store_id}`;
                    break;
                case 5:
                    path = '/set/weixin?from=1';
                    break;
                case 6:
                    path = '';
                    break;
                case 7:
                    path = '/goods/GoodsEvaluate';
                    break;
                case 8:
                    path = '/user/userList';
                    break;
                case 9:
                    path = '/user/userList';
                    break;
                case 10:
                    path = '/user/userList';
                    break;
                default:
                    break;
            }
            this.$axios
                .post(this.$api.homeIndex.readMsg, {
                    message_id: item.id,
                })
                .then((res) => {
                    // console.log(res)
                    // if (res.code == 0) {
                    // }
                });
            that.msgNotification.splice(index, 1);
            that.$emit('msgCountMethod', that.msgNotification.length);
            that.$router.push(path);
        },
        // 快捷入口跳转
        toPath (item) {
            this.$router.push({
                path: item.links,
            });
        },
        calculateRate (today, yesterday) {
            today = today - yesterday;
            return yesterday > 0 ? ((today / yesterday) * 100).toFixed(2) : '0';
        },
        // 获取实时概况数据
        getIndexData () {
            let that = this;
            this.$axios.post(this.$api.homeIndex.rsealTime, {}).then((res) => {
                if (res.code == 0) {
                    let result = res.result;
                    this.rsealTime = [
                        {
                            name: '支付订单数',
                            today: result.pay_order_count,
                            yesterday: result.yesterday_pay_order_count,
                            is_yesterday: 1,
                        },
                        {
                            name: '支付金额(元)',
                            today: result.pay_amount,
                            yesterday: result.yesterday_pay_amount,
                            is_yesterday: 1,
                        },
                        {
                            name: '上架商品数',
                            today: result.goods_count,
                            is_yesterday: 0,
                        },
                        {
                            name: '审核中商品数',
                            today: result.audit_goods_count,
                            is_yesterday: 0,
                        },
                        {
                            name: '待结算（元）',
                            today: result.outstanding_amount,
                            is_yesterday: 0,
                        },
                        {
                            name: '可用店铺余额（元）',
                            today: result.amount_settled,
                            is_yesterday: 0,
                        },
                    ];
                    for (let i in this.rsealTime) {
                        if (i < 3) {
                            this.rsealTime[i].rise = this.calculateRate(parseFloat(this.rsealTime[i].today), parseFloat(this.rsealTime[i].yesterday));
                        }
                    }
                }
            });
        },
        // 获取通知消息
        getMsgList () {
            let that = this;
            this.$axios.post(this.$api.homeIndex.msgNotification, {}).then((res) => {
                if (res.code == 0) {
                    that.msgNotification = res.result.message;
                }
            });
        },
        // 获取快捷入口
        getShortcut () {
            let that = this;
            this.$axios.post(this.$api.homeIndex.shortcut, {}).then((res) => {
                if (res.code == 0) {
                    that.shortcut = res.result;
                }
            });
        },
        // 获取展示数据
        getEcharsData () {
            let that = this;
            this.$axios.post(this.$api.homeIndex.echarsData, {}).then((res) => {
                if (res.code == 0) {
                    that.echarsData = res.result;
                    this.showWhich = this.echarsData.dayData;
                    this.flag = true;
                }
            });
        },
        // 获取商品排行
        getGoodsRanking () {
            let that = this;
            this.$axios.post(this.$api.homeIndex.goodsRanking, {}).then((res) => {
                if (res.code == 0) {
                    that.goodsRanking = res.result;
                }
            });
        },
        // 消息全部忽略
        ignoreall () {
            if (this.msgNotification.length == 0) return;
            let that = this;
            this.$axios
                .post(this.$api.homeIndex.readMsg, {
                    message_id: 'all',
                })
                .then((res) => {
                    if (res.code == 0) {
                        that.$message.success('处理成功');
                        that.msgNotification = [];
                        that.$emit('msgCountMethod', that.msgNotification.length);
                    } else {
                        that.$message.error('处理失败');
                    }
                });
        },
        getTopCompany () {
            this.$axios
                .post(this.$api.homeIndex.topCompany)
                .then((res) => {
                    if (res.code == 0) {
                        let { result } = res
                        this.topCompany = [
                            {
                                name: '企业支付订单数',
                                today: result.pay_order_count
                            },
                            {
                                name: '企业支付金额（元）',
                                today: result.pay_amount
                            },
                            {
                                name: '企业待结算（元）',
                                today: result.outstanding_amount
                            },
                        ]
                    } else {
                        this.$message.error('处理失败');
                    }
                });
        }
    },
    watch: {
        showDay (val) {
            if (val == 0) {
                this.showWhich = this.echarsData.dayData;
            } else if (val == 1) {
                this.showWhich = this.echarsData.yesterdayData;
            } else if (val == 2) {
                this.showWhich = this.echarsData.sevenDayData;
            } else if (val == 3) {
                this.showWhich = this.echarsData.thirtyDayData;
            }
        },
    },
    filters: {
        setTiem (index) {
            return getDateformat(index);
        },
    },
};
</script>

<style lang="less" scoped>
.indexbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    font-size: 14px;

    .indexBoxLeft {
        flex: 26;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 8px;

        &>div {
            width: 100%;
            background-color: #fff;
            // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
        }

        .realtime {
            flex: 0 0 303px;
            margin-bottom: 8px;

            .realtimetop {
                display: flex;
                align-items: center;
                padding: 16px 32px;

                .title {
                    font-weight: bold;
                    font-size: 14px;

                    &:before {
                        content: '';
                        height: 14px;
                        border-right: 3px solid #1467ff;
                        margin-right: 8px;
                    }
                }

                .timeTxt,
                .iconbox {
                    color: #969699;
                }

                .timeTxt {
                    margin: 0 8px 0 16px;
                }

                .iconbox i {
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .realtimebot {
                height: calc(100% - 52px);
                padding: 0 30px 23px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .listItem {
                    display: flex;
                    flex-wrap: nowrap;

                    .topItem {
                        width: 270px;
                        flex-shrink: 0;
                        height: 104px;
                        border: 1px solid #f2f3f5;
                        border-radius: 8px;
                        margin-right: 24px;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 20px;
                        align-items: center;

                        .today {
                            text-align: left;

                            p:first-child {
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 30px;
                            }

                            p:last-child {
                                line-height: 30px;
                                color: #979899;
                            }
                        }

                        .yesterday {
                            text-align: right;

                            p:first-child {
                                color: #979899;
                                line-height: 30px;
                            }

                            p:last-child {
                                line-height: 30px;
                                font-size: 12px;

                                i {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }

        .quickaccess {
            flex: 0 0 80px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            .titleBox {
                padding: 16px 32px;
                color: #17171a;
                font-weight: bold;
                font-size: 14px;

                &:before {
                    content: '';
                    height: 14px;
                    border-right: 3px solid #1467ff;
                    margin-right: 8px;
                }

                span {
                    font-weight: normal;
                    font-size: 14px;
                    color: #969699;
                }
            }

            .qickList {
                display: flex;
                padding: 0 32px;

                .quickItem {
                    cursor: pointer;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-right: 50px;

                    img {
                        margin-right: 10px;
                    }

                    span {
                        color: #ea4335;
                    }
                }

                .addBox {
                    display: flex;
                    align-items: center;
                    margin-left: 32px;
                }

                .addQuick {
                    // width: 56px;
                    // height: 56px;
                    border-radius: 50%;
                    margin-right: 10px;
                    cursor: pointer;
                    // border: 1px #ccc dashed;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 25px;
                    }
                }
            }
        }

        .paymentamount {
            flex: 1;
            position: relative;
            padding-bottom: 10px;

            .titleBox {
                display: flex;
                justify-content: space-between;
                padding: 16px 32px 0 32px;

                &>p {
                    color: #17171a;
                    font-weight: bold;
                    font-size: 14px;

                    &:before {
                        content: '';
                        height: 14px;
                        border-right: 3px solid #1467ff;
                        margin-right: 8px;
                    }
                }

                &>div {
                    display: flex;
                }

                &>div>p {
                    width: 56px;
                    height: 24px;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #f0f0f5;
                    margin-right: 20px;
                    cursor: pointer;
                    color: #969699;
                    user-select: none;
                }

                &>div>.active {
                    background-color: #5654f3;
                    color: #fff;
                }
            }

            #eBox {
                width: 100%;
                height: calc(100% - 56px);
                min-height: 312px;
            }

            .totalData {
                width: 100%;
                display: flex;
                justify-content: space-around;
                position: absolute;
                top: 50px;
                z-index: 1000;

                .xds {
                    text-align: center;

                    .xdsNum {
                        color: #ff7230;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    .xdsTxt {
                        font-size: 12%;
                    }
                }
            }
        }
    }

    .indexBoxRight {
        flex: 10;
        min-width: 478px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>div {
            width: 100%;
        }

        .righttop {
            height: 303px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 8px;
            background-color: #fff;

            &>div {
                width: 100%;
                // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
            }

            .righttopT {
                height: 303px;
                padding: 16px 24px 20px 24px;
                margin-bottom: 8px;

                .titleBox {
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        color: #17171a;

                        &:before {
                            content: '';
                            height: 14px;
                            border-right: 3px solid #1467ff;
                            margin-right: 8px;
                        }
                    }

                    .ignoreall {
                        color: #979899;
                        cursor: pointer;
                    }
                }

                .listBox {
                    height: calc(100% - 32px);
                    padding-right: 10px;
                    overflow: hidden;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 5px !important;
                        height: 5px !important;
                    }

                    .itemBox {
                        display: flex;
                        margin-bottom: 12px;
                        justify-content: space-between;
                        align-items: center;

                        .content {
                            width: 192px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #656566;

                            span {
                                display: inline-block;
                                width: 4px;
                                height: 4px;
                                margin-bottom: 2.5px;
                                background: #1467ff;
                                border-radius: 50%;
                                margin-right: 5px;
                            }
                        }

                        .time {
                            color: #cacbcc;
                            font-size: 12px;
                        }

                        .todeal {
                            color: #1467ff;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .righttopB {
            height: 80px;
            display: flex;
            justify-content: space-between;
            padding: 16px 64px;
            align-items: center;
            background: #fff;
            margin-bottom: 8px;

            &>div {
                display: flex;
                align-items: center;
                cursor: pointer;

                p {
                    font-size: 14px;
                    color: #17171a;
                }
            }

            img {
                margin-right: 10px;
            }
        }

        .rightbot {
            flex: 1;
            background-color: #fff;
            box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
            padding: 16px 24px 0 24px;

            .titleBox {
                font-size: 14px;
                font-weight: bold;
                color: #17171a;
                margin-bottom: 12px;

                &:before {
                    content: '';
                    height: 14px;
                    border-right: 3px solid #1467ff;
                    margin-right: 8px;
                }
            }

            .goodsRanking-box {
                border: 1px solid rgba(226, 193, 149, 0.2);
                height: calc(100% - 57px);
                padding: 15px 30px 30px 30px;
                margin-bottom: 15px;
                border-radius: 8px;

                .title {
                    color: #cf984e;
                    display: flex;

                    span:nth-child(1) {
                        width: 40px;
                    }

                    span:nth-child(2) {
                        flex: 1;
                        text-align: center;
                    }

                    span:nth-child(3) {
                        width: 80px;
                        text-align: center;
                    }
                }
            }

            .goodsRanking {
                margin-top: 10px;
                height: calc(100% - 28px);
                overflow: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px !important;
                    height: 5px !important;
                }

                .goodsItem {
                    height: 72px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .rankingNum {
                        width: 40px;
                        text-align: center;
                        color: #969699;
                        font-weight: bold;
                        font-size: 16px;
                    }

                    .goodsInfo {
                        display: flex;
                        flex: 1;

                        .imgBox {
                            padding-left: 20px;

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 4px;
                            }

                            margin-right: 16px;
                        }

                        .infoRight {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding: 2px 0;

                            .goodsName {
                                width: 205px;
                                // text-overflow: -o-ellipsis-lastline;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                // display: -webkit-box;
                                // -webkit-line-clamp: 2;
                                // -webkit-box-orient: vertical;
                                margin-right: 20px;
                            }
                        }
                    }

                    .salesNum {
                        width: 80px;
                        font-size: 14px;
                        color: #969699;
                    }
                }
            }
        }
    }
}

.returnBtn {
    margin-left: 20px;
}

.dialogBox {
    .qickList {

        .selected,
        .not-selected {
            ul {
                display: flex;
                margin-top: 15px;
                flex-wrap: wrap;

                li {
                    border-radius: 4px;
                    border: 1px solid #f2f3f5;
                    height: 60px;
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                    margin-bottom: 20px;
                    justify-content: space-between;
                    cursor: pointer;

                    span {
                        margin: 0 15px 0 10px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    i {
                        color: #cacbcc;
                    }
                }

                .border {
                    border: 1px solid #409eff;
                }
            }
        }
    }
}
</style>
