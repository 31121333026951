import axios from '../../util/axios';
import router from '../../router';
import api from '../../util/api';
import { Message } from 'element-ui';
import { resetVuex } from '@/util/util';
const default_state = {
  menuLeft: [],
  userInfo: {},
  shopInfo: {},
  relationMenu: [],
  loadingFlag: false,
};
const state = {
  menuLeft: [],
  userInfo: {},
  shopInfo: {},
  relationMenu: [],
  loadingFlag: false,
};

const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state);
    for (let i in data) {
      state[i] = data[i];
    }
  },
  setRoutes(state, data) {
    state.menuLeft = data;
  },
  setRelationMenu(state, data) {
    state.relationMenu = data;
  },
  getUserInfo(state, data) {
    state.userInfo = data || {};
  },
  getShopInfo(state, data) {
    state.shopInfo = data || {};
  },
  changeLoadingFlag(state, data) {
    state.loadingFlag = data;
  },
};

const actions = {
  outLogin() {
    axios.post(api.user.logout).then((res) => {
      if (res.code == 0) {
        if (res.code == 0) {
          Message({
            message: '退出登录成功',
            type: 'success',
          });
          resetVuex();
          sessionStorage.removeItem('token');
          router.push('/login');
        }
      } else {
        Message.error(res.msg);
      }
    });
  },
  getUserInfo({ commit }) {
    axios.post(api.user.userInfo).then((res) => {
      if (res.code == 0) {
        commit('getUserInfo', res.result);
      } else {
        Message.error(res.msg);
      }
    });
  },
  getShopInfo({ commit }) {
    axios.post(api.user.shopInfo).then((res) => {
      if (res.code == 0) {
        commit('getShopInfo', res.result);
      } else {
        Message.error(res.msg);
      }
    });
  },
  get_menu({ commit }, data = {}) {
    axios.post(api.user.getMenu).then((res) => {
      if (res.code == 0) {
        let arr = [];
        let list = res.result;
        for (let i in list) {
          let children = list[i].childs || [];
          let obj = {
            path: list[i].auth_key,
            meta: {
              icon: list[i].auth_icon,
              title: list[i].auth_meta,
              name: list[i].auth_name,
              rules: 1,
            },
            children: [],
          };
          for (let y in children) {
            obj.children.push({
              path: children[y].auth_key,
              name: children[y].auth_name,
              meta: {
                rules: 1,
                name: children[y].auth_name,
              },
            });
          }
          arr.push(obj);
        }
        commit('setRoutes', arr);
        if (arr.length) data.success();
      } else {
        Message.error(res.msg);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
