import axios from '../../util/axios';
import api from '../../util/api';
const default_state = {
  goodsTypeList: [],
};
const state = {
  goodsTypeList: [],
};
const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state);
    for (let i in data) {
      state[i] = data[i];
    }
  },
  getTypeList(state, data) {
    state.goodsTypeList = data;
  },
};

const actions = {
  getTypeList({ commit }, data = {}) {
    axios.post(api.goods.goodsClass, data).then((res) => {
      if (res.code == 0) {
        let data = res.result.list;
        for (let i in data) {
          data[i].grade = '一级';
          data[i].is_show = data[i].is_show ? !0 : !1;
        }
        commit('getTypeList', data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
